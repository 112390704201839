import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { PriceScaleFilter } from 'src/app/shared/models/availabilty/section.model';
import { Venues } from 'src/app/shared/models/event.model';
import { APP_CONFIG } from 'src/configuration/configuration';
@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrl: './selector.component.css'
})
export class SelectorComponent {

  // Data
  @Input()  venues!:        Venues;                                                     // Objeto con los distintos Venues de los eventos
  @Input()  priceScales!:   PriceScaleFilter;                                           // Objeto con las secciones (Price Scales) disponibles
  @Input()  isMembership:   boolean                             = false;                // Indica si el usuario tiene membresías
  @Output() selected:       EventEmitter<keyof Venues | string> = new EventEmitter();   // Emisor de Eventos del Selector. Puede emitir la key de los Venues o la Price Scale seleccionada

  // Services
  protected currency:       string  = inject(APP_CONFIG).general.currency;

  // State
  protected show:           boolean = false;                                            // Muestra o esconde las opciones del selector.

  // Template Vars
  protected defaultVenue:            string  = 'Filter Events';     
  protected get defaultPriceScale(): string {
    return !this.isMembership ? 'Select your price scale' : 'Select Membership Level';
  }

  /**
   * Devuelve (si hay) la Venue seleccionada.
   * @returns string | undefined
   */
  protected get selectedVenue():   string | undefined {
    if(this.venues){
      const active = Object.values(this.venues).filter(venue => venue.active === true)[0];
      return active.name === 'ALL' ? this.defaultVenue : active.name;
    }
    return
  }

  /**
   * Devuelve (si hay) la sección (Price Scale) seleccionada.
   */
  protected get selectedPriceScale(): string | undefined {
    if(this.priceScales){
      const active = Object.values(this.priceScales).filter(pricescale => pricescale.active === true)[0];
      return active ? active.name : this.defaultPriceScale;
    }
    return this.defaultPriceScale;
  }

  /**
   * Muestra o esconde las opciones del selector. 
   */
  public toggleSelect(): void {
    this.show = !this.show;
  }

  /**
   * Emite la key de la Venue seleccionada. A continuación esconde las opciones del selector. 
   * @param {string | number} venue - Key Of Venues
   */
  protected selectVenue(venue: keyof Venues): void {
    this.selected.emit(venue);
    this.show = false;
  }

  /**
   * Emite la Key de la Sección o Price Scale seleccionada. A continuación esconde los resultados. 
   * @param {string | number} section 
   */
  protected selectPriceScale(pricescale: keyof PriceScaleFilter): void {
    this.selected.emit(pricescale);
    this.show = false;
  }

}
