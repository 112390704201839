<div class="border flex flex-col rounded-xl overflow-hidden container mx-auto md:max-w-4xl lg:max-w-5xl 2xl:max-w-7xl xl:w-[80rem] bg-white dark:bg-slate-900 dark:border-secondary/50">
    
    <!-- HEADER -->
    <app-card-header
        [id]="data.id"
        [name]="data.name"
        [venue]="data.venue"
        [date]="date"
        [available]="available"
        [showButton]="showButton"
        (selectItem)="selectItem()"
    />
    
    <div *ngIf="showText && data.description" class="bg-gray-50 dark:bg-gray-950/50 text-center py-5 px-4 lg:flex lg:flex-wrap lg:p-10">
        <h4 class="text-secondary mb-3 mt-3 font-medium lg:w-2/12 lg:my-0 lg:text-sm lg:text-left">
            Information
        </h4>
        <p class="text-darkgray text-center text-xs lg:w-10/12 lg:text-left lg:text-sm dark:text-gray-500 prose max-w-none lg:max-w-3xl">
           {{data!.description}}
        </p>
    </div>
    

    <!-- CONTENT  -->
    <ng-content></ng-content>
    
</div>