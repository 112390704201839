import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from 'src/app/shared/models/event.model';
import { Inventory } from 'src/app/shared/models/inventory.model';
import { Package } from 'src/app/shared/models/package.model';


/**
 * ### Card Component
 * Este componente se utiliza en la selección de eventos, paquetes, amigos...
 * Se compone de distintos inputs que modifican su apariencia y funcionamiento. Sirve para renderizar datos, habitualmente como lista
 * y se compone de una parte superior; el detalle, y una de inferior, la descripción o contenido arbitrario. 
 * 
 * Por ejemplo:
 * En la selección de eventos, tenemos como parte superior el detail Event y como parte inferior la información del evento.
 * En la selección de amigos, tenemos una parte superior similar pero sin botón y la parte inferior es la lista de amigos. 
 * 
 * Para ello, el componente se divide en dos y cuenta con un ng-content para contenido arbitrario en la parte inferior de la Card.
 */
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  // Inputs
  @Input()  data!:      Event | Package | Inventory; // Data
  @Input()  ticket!:    Inventory;                   // Ticket Data
  @Input()  showButton: boolean = true;              // Show or hide button (events)
  @Input()  showText:   boolean = true;              // Show or hide descriptions
  
  // Outputs
  @Output() cardEvents: EventEmitter<void> = new EventEmitter<void>(); // Emisor de Eventos (Events | Packages | etc)
    
  
  // Getters
  public get date(): Date | undefined {
    return (this.data as Event | Inventory).date ?? undefined
  }

  public get available(): boolean {
    const event = this.data as Event,
          pack  = this.data as Package;
    
    if(event.single_ticket_sale_allowed || pack.saleAllowed){
      return true;
    }

    return false;
    
  }

  // Methods

  /**
   * Emisor de eventos para los card details.
   * Retorna el valor del objeto Event o Package de la Card seleccionada para consumir el dato
   * en el componente padre. 
   * @returns {void} 
   */
  protected selectItem(): void {
    this.cardEvents.emit();
  }
  
}
