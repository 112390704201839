import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrl: './card-header.component.css'
})
export class CardHeaderComponent {

  // INPUTS 
  @Input({required: true})  id!:           number | string;
  @Input({required: true})  name!:         string;
  @Input({required: true})  venue!:        string;
  @Input({required: true})  available!:    boolean;
  @Input({required: true})  showButton!:   boolean;
  @Input({required: false}) date!:         Date | undefined;


  // OUTPUTS
  @Output() 
  selectItem: EventEmitter<void> = new EventEmitter<void>();

  // METHODS 
  /**
   * Emite a la card cuando se pulsa el botón. 
   * @returns {void}
   */
  protected selectTicket(): void {
    this.selectItem.emit();
  }

}
